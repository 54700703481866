var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5 relative",attrs:{"flat":""}},[_c('div',{staticClass:"d-flex align-center"},[_c('v-card-title',[_vm._v("Quy trình xử lý")]),(_vm.guaranteeVoucher.export_id)?_c('div',{staticClass:"ml-auto d-flex align-center mr-3"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({class:[
              'mr-3 text-body-1 primary--text font-weight-medium cursor-pointer',
              {
                'cursor-wait':
                  _vm.orderStatusRequest.value === 'loading-getOrderById'
              }
            ],on:{"click":function($event){$event.stopPropagation();return _vm.viewSaleReceipt(_vm.guaranteeVoucher.export_id)}}},'span',attrs,false),on),[_vm._v(" "+_vm._s(_vm.guaranteeVoucher.export.code)+" ")])]}}],null,false,586481006)},[_c('span',[_vm._v("Xem chi tiết")])]),(_vm.guaranteeVoucher.export.status === 1)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"green","outlined":"","small":""}},[_vm._v(" Thành công ")]):_vm._e(),(_vm.guaranteeVoucher.export.status === -1)?_c('v-chip',{staticClass:"font-weight-bold",attrs:{"color":"red","outlined":"","small":""}},[_vm._v(" Huỷ ")]):_vm._e(),(_vm.guaranteeVoucher.export_id && _vm.type === 'repair')?_c('v-btn',{staticClass:"ml-3",attrs:{"icon":"","color":"primary","to":{
          name: 'print_sale-receipt',
          params: {
            saleReceiptId: _vm.guaranteeVoucher.export_id
          }
        },"target":"_blank"}},[_c('v-icon',[_vm._v("mdi-printer")])],1):_vm._e()],1):_vm._e()],1),_c('v-container',{staticClass:"px-1 pt-0 pb-5",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mx-0",staticStyle:{"height":"100%"}},_vm._l((4),function(num){return _c('v-col',{key:num,staticClass:"col-wrap py-0",attrs:{"cols":"3"}},[_c('col-step',{attrs:{"step":num,"isButton":_vm.type},on:{"showModalAddCommentHaveFiles":_vm.showModalAddCommentHaveFiles,"previewImage":_vm.previewImage}})],1)}),1)],1),_c('modal-confirm-give-customer',{attrs:{"type":_vm.type}}),_c('modal-add-comment-have-files',{attrs:{"comment":_vm.comment},on:{"previewImage":_vm.previewImage,"resetComment":_vm.resetComment}}),_c('modal-preview-image',{attrs:{"url":_vm.selectedImage}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }