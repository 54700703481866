<template>
  <div style="height: 100%">
    <!-- Start: Top -->
    <div class="d-flex flex-row align-center">
      <div class="text-h5 font-weight-bold">
        Xử lý {{ type === "guarantee" ? "bảo hành" : "sửa chữa" }}
      </div>
      <v-chip
        v-if="guaranteeVoucher.huy_status && guaranteeVoucher.huy_status === 1"
        class="white font-weight-bold ml-3"
        color="red accent-2"
        outlined
      >
        Đã hủy
      </v-chip>

      <v-spacer></v-spacer>

      <v-btn
        v-if="isShowCancelBtn"
        class="red lighten-4 red--text text-accent-2 rounded-lg mr-3"
        depressed
        :disabled="
          guaranteeVoucherStatusRequest.value ===
            'loading-cancelGuaranteeVoucher'
        "
        :loading="
          guaranteeVoucherStatusRequest.value ===
            'loading-cancelGuaranteeVoucher'
        "
        @click="
          guaranteeVoucherStatusRequest.value ===
          'loading-cancelGuaranteeVoucher'
            ? null
            : openModalCancelingGuaranteeVoucher()
        "
      >
        Hủy
      </v-btn>

      <v-btn
        v-if="
          guaranteeVoucher.id &&
            guaranteeVoucher.huy_status !== 1 &&
            guaranteeVoucher.step < 4
        "
        class="rounded-lg"
        color="primary"
        depressed
        :disabled="
          guaranteeVoucherStatusRequest.value ===
            'loading-updateGuaranteeVoucher'
        "
        :loading="
          guaranteeVoucherStatusRequest.value ===
            'loading-updateGuaranteeVoucher'
        "
        @click="
          guaranteeVoucherStatusRequest.value ===
          'loading-updateGuaranteeVoucher'
            ? null
            : updateGuaranteeVoucher()
        "
      >
        Lưu
      </v-btn>
    </div>
    <!-- End: Top -->
    <!-- Start: Cancel reason -->
    <v-card
      v-if="guaranteeVoucher.huy_status && guaranteeVoucher.huy_status === 1"
      class="mt-5"
      flat
    >
      <div class="amber lighten-5 text-body-1 px-3 py-2">
        <span class="font-weight-bold">Lý do hủy:</span>
        {{ guaranteeVoucher.ly_do_huy }}
      </div>
    </v-card>
    <!-- End: Cancel reason -->

    <!-- Start: Main content -->
    <div class="main-detail--content mt-5">
      <card-replace-accessories
        class="card-accessories"
        :type="type"
        :payment-total="paymentTotal"
      ></card-replace-accessories>
      <card-processing class="card-processing" :type="type"></card-processing>
    </div>
    <!-- End: Main content -->

    <modal-sale-payment></modal-sale-payment>
  </div>
</template>

<script>
import CardProcessing from "./components/CardProcessing/index";
import CardReplaceAccessories from "./components/CardReplaceAccessories/index";
import ModalSalePayment from "../modal/ModalPaymentAmount";

export default {
  props: {
    type: {
      type: String,
      require: true
    }
  },

  components: {
    CardProcessing,
    CardReplaceAccessories,
    ModalSalePayment
  },

  computed: {
    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },

    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    },

    isShowCancelBtn() {
      if (this.guaranteeVoucher.id) {
        if (
          (this.type === "guarantee" &&
            this.guaranteeVoucher.huy_status !== 1) ||
          (this.type === "repair" &&
            this.guaranteeVoucher.huy_status !== 1 &&
            !this.guaranteeVoucher.export_id)
        ) {
          return true;
        }
      }

      return false;
    },

    paymentTotal() {
      return this.$store.getters["SALE/paymentTotal"];
    },

    receiptVoucherStatusRequest() {
      return this.$store.getters["RECEIPT_VOUCHER/statusRequest"];
    },

    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },

    serialInfo() {
      return this.$store.getters["GUARANTEE_VOUCHER/serialInfo"];
    },

    outerSerial() {
      return this.$store.getters["GUARANTEE_VOUCHER/outerSerial"];
    }
  },

  methods: {
    openModalCancelingGuaranteeVoucher() {
      this.$modal.show({
        name: "modal-guarantee-voucher-cancel",
        payload: {
          item: this.guaranteeVoucher
        }
      });
    },

    async updateGuaranteeVoucher() {
      // Set type
      if (this.$route.query.type === "guarantee") {
        this.guaranteeVoucher.type = 1;
      } else {
        this.guaranteeVoucher.type = 2;
      }

      const objectSender = JSON.parse(JSON.stringify(this.guaranteeVoucher));
      let serials = [];

      await objectSender.options.forEach(option => {
        const arr = option.serials.map(item => {
          return {
            id: item.id,
            sold_price:
              this.guaranteeVoucher.type === 1
                ? 0
                : option.unit_sold_price - Number(option.rebate_amount),
            unit_sold_price:
              this.guaranteeVoucher.type === 1 ? null : option.unit_sold_price
          };
        });

        serials = serials.concat(arr);
      });

      objectSender.xuat_serial_ids = serials;

      objectSender.outer_serial_id = this.outerSerial
        ? this.outerSerial.id
        : null;

      // Request update
      await this.$store.dispatch(
        "GUARANTEE_VOUCHER/updateGuaranteeVoucher",
        objectSender
      );
      if (
        this.guaranteeVoucherStatusRequest.value ===
        "success-updateGuaranteeVoucher"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
        this.isShowButtonSaveServiceFee = false;
        this.isShowButtonSaveBranchID = false;
      }
      if (
        this.guaranteeVoucherStatusRequest.value ===
        "error-updateGuaranteeVoucher"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Có lỗi xảy ra. Vui lòng kiểm tra lại sau!"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-detail--content {
  max-height: calc(100% - 36px - 20px);
  overflow-y: auto;
}
</style>
