<template>
  <v-card class="card-wrap pa-5" flat>
    <!-- Start: Created employee and branch -->
    <div class="">
      <span class="font-weight-bold">Tạo bởi: </span>
      <span>{{ user.name }}</span>
    </div>

    <div class="mt-2">
      <span class="font-weight-bold">Thời gian tạo: </span>
      <template v-if="guaranteeVoucher.id">{{
        guaranteeVoucher.created_at
      }}</template>
      <template v-else>{{ getToday() }}</template>
    </div>

    <div class="mt-3">
      <div class="font-weight-bold mb-2">Hạn xử lý</div>
      <div class="d-flex flex-col">
        <div>
          <tp-input-date
            v-model="dateDueDate"
            custom-class="text-body-1 mt-0"
            dense
            hide-details
            outlined
            placeholder="Chọn ngày"
            appendIcon="mdi-calendar"
            @input="changeDueDate"
            @open-picker="openPicker"
            @close-picker="closePicker"
          ></tp-input-date>
        </div>

        <div
          v-show="guaranteeVoucher.reason"
          :key="key"
          class="text-body-2 mt-1"
        >
          Lý do lùi lịch : {{ guaranteeVoucher.reason }}
        </div>
      </div>
    </div>

    <div
      v-if="
        guaranteeVoucher.histories_due_date &&
          guaranteeVoucher.histories_due_date.length
      "
      class="d-flex flex-col flex-column-reverse gap-3 mt-3"
      style="max-height: 600px; overflow-y: auto;"
    >
      <v-card
        v-for="(item, index) in guaranteeVoucher.histories_due_date"
        :key="index + item.new_due_date"
        class="d-flex flex-col gap-1 pa-4"
        color="grey lighten-4"
        flat
      >
        <div class="d-flex align-center gap-1">
          <span class="font-weight-bold">Lùi lịch lần {{ index + 1 }}: </span>
          <span> {{ item.user_name }} </span>
        </div>

        <div class="d-flex gap-3 align-center flex-wrap">
          <div class="d-flex flex-col flex-shrink-0">
            <span class="font-weight-bold">Hạn cũ:</span>
            <span>{{ item.old_due_date }}</span>
          </div>
          <div class="d-flex flex-col flex-shrink-0">
            <span class="font-weight-bold">Hạn mới:</span>
            <span>{{ item.new_due_date }}</span>
          </div>
        </div>

        <div class="d-flex align-center gap-1">
          <span class="font-weight-bold">Lý do: </span>
          <span> {{ item.reason }} </span>
        </div>
      </v-card>
    </div>

    <v-divider class="mt-5 mb-5"></v-divider>

    <div>
      <div class="font-weight-bold mb-2">Chi nhánh</div>
      <v-autocomplete
        v-model="guaranteeVoucher.branch_id"
        class="tp-filter-autocomplete"
        clearable
        :items="branches"
        :disabled="!!guaranteeVoucher.id"
        dense
        hide-details
        hide-selected
        item-text="name"
        item-value="id"
        no-data-text="Không có dữ liệu"
        outlined
        single-line
        placeholder="Chọn chi nhánh"
        @change="onChangeBranch"
      ></v-autocomplete>
    </div>
    <!-- End: Created employee and branch -->
    <!-- Start: Assignee -->
    <div class="mt-4">
      <div class="font-weight-bold mb-2">
        Nhân viên phụ trách
      </div>
      <v-autocomplete
        v-model="guaranteeVoucher.assigned_to_id"
        class="tp-filter-autocomplete"
        clearable
        :items="saleEmployees"
        :disabled="!!guaranteeVoucher.id"
        dense
        hide-details
        hide-selected
        item-text="name"
        item-value="id"
        no-data-text="Không có dữ liệu"
        outlined
        single-line
        placeholder="Chọn nhân viên"
      ></v-autocomplete>
    </div>
    <!-- Start: Reception technique -->
    <div class="mt-4">
      <div class="font-weight-bold mb-2">
        Kỹ thuật tiếp nhận
      </div>
      <v-autocomplete
        v-model="guaranteeVoucher.take_participant_id"
        :disabled="!!guaranteeVoucher.id"
        class="tp-filter-autocomplete"
        clearable
        :items="employees"
        dense
        hide-details
        hide-selected
        item-text="name"
        item-value="id"
        no-data-text="Không có dữ liệu"
        outlined
        single-line
        placeholder="Chọn kỹ thuật tiếp nhận"
      ></v-autocomplete>
    </div>
    <!-- End: Reception technique -->
    <!-- Start: Payer -->
    <div class="mt-4">
      <div class="mb-4">
        <v-checkbox
          v-model="guaranteeVoucher.require_hardware"
          label="Phần cứng"
          class="mt-0 mb-0"
          hide-details
          dense
          :disabled="!!guaranteeVoucher.id"
        ></v-checkbox>
      </div>
      <div v-if="guaranteeVoucher.step === 4">
        <div class="font-weight-bold mb-2">Người trả khách</div>
        <v-autocomplete
          v-model="guaranteeVoucher.return_participant_id"
          class="tp-filter-autocomplete"
          clearable
          :items="employees"
          disabled
          dense
          hide-details
          hide-selected
          item-text="name"
          item-value="id"
          no-data-text="Không có dữ liệu"
          outlined
          single-line
          placeholder="Chọn người trả khách"
        ></v-autocomplete>
      </div>
    </div>
    <div v-if="guaranteeVoucher && guaranteeVoucher.participants">
      <div
        v-for="(participant, index) in guaranteeVoucher.participants.filter(
          item => !['TECHNIQUE_HARDWARE', 'TECHNIQUE'].includes(item.role)
        )"
        :key="`participant-${index}`"
      >
        <v-divider class="mt-5 mb-3"></v-divider>
        <div>
          <div class="font-weight-bold d-flex align-center mb-2">
            <span>{{ getNameRole(participant.role) }}</span>
          </div>
          <v-autocomplete
            v-model="participant.user_id"
            class="font-weight-medium text-body-1 price--text-right mt-0 pt-0"
            dense
            flat
            hide-details
            :items="techEmployees"
            item-text="name"
            item-value="id"
            no-data-text="Không có dữ liệu"
            placeholder="Chọn kỹ thuật viên"
            disabled
          ></v-autocomplete>
          <v-text-field
            v-if="participant.role === 'SHIPPING'"
            v-model="participant.value"
            class="text-body-1 mt-4"
            clearable
            dense
            hide-details="auto"
            outlined
            placeholder="Nhập số quãng đường (km)"
            disabled
          ></v-text-field>
        </div>
        <div class="mt-4">
          <v-textarea
            v-model="participant.note"
            class="text-body-1 mt-2"
            auto-grow
            hide-details
            placeholder="Nhập ghi chú yêu cầu hỗ trợ của khách"
            outlined
            rows="3"
            single-line
            disabled
          ></v-textarea>
        </div>
      </div>
    </div>
    <v-btn
      block
      class="rounded-lg my-4"
      color="primary"
      depressed
      @click="showModalAddRoleServer"
      v-if="guaranteeVoucher.id"
    >
      <v-icon left>mdi-plus</v-icon> Thêm phục vụ
    </v-btn>
    <!-- End: Payer -->
    <div class="mt-4">
      <div class="font-weight-bold mb-3 d-flex align-center">
        <span>Mức độ rủi ro</span>
      </div>
      <div>
        <v-select
          v-model="guaranteeVoucher.risk"
          class="font-weight-medium text-body-1 price--text-right mt-0 pt-0"
          dense
          hide-details
          :items="RISK_STATUSES"
          item-text="label"
          item-value="value"
          :menu-props="{ nudgeTop: '-40px' }"
          no-data-text="Không có dữ liệu"
          outlined
          placeholder="Mức độ rủi ro"
        ></v-select>
      </div>
    </div>

    <div class="mt-4">
      <div class="font-weight-bold mb-3 d-flex align-center">
        <span>Đối tác {{ textModule }}:</span>
      </div>
      <div>
        <v-select
          v-model="guaranteeVoucher.service_supplier_id"
          class="font-weight-medium text-body-1 price--text-right mt-0 pt-0"
          dense
          hide-details
          :items="suppliersRepairGuarantee"
          item-text="name"
          item-value="id"
          :menu-props="{ nudgeTop: '-40px' }"
          no-data-text="Không có dữ liệu"
          outlined
          :placeholder="`Đối tác ` + textModule"
          @change="changeBranchID"
          :disabled="guaranteeVoucher.huy_status === 1"
        ></v-select>
      </div>
    </div>
    <div class="mt-4">
      <div class="font-weight-bold d-flex align-center mb-2">
        <span>Phí {{ textModule }}</span>
      </div>
      <div class="font-weight-medium">
        <tp-input-price
          v-model="guaranteeVoucher.service_fee"
          custom-class="text-body-1 mt-0 pt-0"
          dense
          hide-details
          outlined
          @change="changeServiceFee($event)"
          :disabled="guaranteeVoucher.huy_status === 1"
        ></tp-input-price>
      </div>
    </div>
    <!-- End: Assignee -->
    <v-divider class="mt-5 mb-4"></v-divider>
    <!-- Start: Serial -->
    <div>
      <div class="font-weight-bold mb-2">
        <template
          v-if="guaranteeVoucher.id || (outerSerial && outerSerial.customer_id)"
        >
          Thông tin máy {{ type === "guarantee" ? "bảo hành" : "sửa chữa" }}
        </template>
        <template v-else>
          Nhập serial máy {{ type === "guarantee" ? "bảo hành" : "sửa chữa" }}
        </template>
      </div>

      <!--      <span-->
      <!--        v-if="guaranteeVoucher.id || (outerSerial && outerSerial.customer_id)"-->
      <!--      ></span>-->
      <!--      <v-text-field-->
      <!--        v-else-->
      <!--        v-model="serialNumber"-->
      <!--        class="text-body-1"-->
      <!--        clearable-->
      <!--        dense-->
      <!--        hide-details="auto"-->
      <!--        outlined-->
      <!--        placeholder="Nhập số serial và ấn enter"-->
      <!--        @keypress.enter="getSerialInfoBySerialNumber()"-->
      <!--      ></v-text-field>-->

      <!-- Start: Serial info -->
      <template v-if="serialInfo && serialInfo.product_info">
        <!-- Start: Serial detail -->
        <v-card class="grey lighten-4 px-5 py-4 mt-4" flat>
          <div class="mb-1">
            <span class="font-weight-bold">Serial: </span>
            <span>{{ serialInfo.serial_number }}</span>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">Tên sản phẩm: </span>
            <span>{{ serialInfo.product_info.product_name }}</span>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">SKU:</span>
            {{ serialInfo.product_info.option_sku }} -
            <span
              class="text-subtitle"
              v-html="serialInfo.product_info.option_name"
            ></span>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">Giá bán:</span>
            <span>{{
              serialInfo.product_info.sold_price | formatCurrency
            }}</span>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">Gói bảo hành: </span>
            <span>{{ serialInfo.guarantee_info.name }}</span>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">Ngày kích hoạt: </span>
            <span>{{ serialInfo.guarantee_info.trigger_time }}</span>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">Hạn bảo hành: </span>
            <span
              class="font-weight-bold"
              :class="
                compareDateWithToday(serialInfo.guarantee_info.due_time)
                  ? 'green--text'
                  : 'red--text'
              "
            >
              {{ serialInfo.guarantee_info.due_time }}
            </span>
          </div>
        </v-card>
        <!-- End: Serial detail -->
        <!-- Start: Customer info -->
        <v-card class="grey lighten-4 px-5 py-4 mt-4" flat>
          <div class="mb-1">
            <span class="font-weight-bold">Khách hàng: </span>
            <span>{{ serialInfo.bought_by.name }}</span>
          </div>
          <div
            class="mb-1"
            v-if="serialInfo.bought_by && serialInfo.bought_by.phone"
          >
            <span class="font-weight-bold">SĐT: </span>
            <span>{{
              serialInfo.bought_by.phone | VMask("### ### ####")
            }}</span>
          </div>
          <div>
            <span class="font-weight-bold">Hóa đơn bán: </span>
            <span>{{ serialInfo.bought_by.hdb_code }}</span>
          </div>
        </v-card>
        <!-- End: Customer info -->
      </template>
      <template v-if="outerSerial && outerSerial.customer_id">
        <!-- Start: Serial detail -->
        <v-card class="grey lighten-4 px-5 py-4 mt-4" flat>
          <div class="mb-1">
            <span class="font-weight-bold">Serial: </span>
            <span>{{ outerSerial.serial_number }}</span>
          </div>
          <div class="mb-1">
            <span class="font-weight-bold">Tên sản phẩm: </span>
            <span>{{ outerSerial.product_name }}</span>
          </div>
        </v-card>
        <!-- End: Serial detail -->
        <!-- Start: Customer info -->
        <v-card class="grey lighten-4 px-5 py-4 mt-4" flat>
          <div class="mb-1">
            <span class="font-weight-bold">Khách hàng: </span>
            <span v-if="outerSerial.customer && outerSerial.customer.name">{{
              outerSerial.customer.name
            }}</span>
          </div>
          <div
            class="mb-1"
            v-if="outerSerial.customer && outerSerial.customer.phone"
          >
            <span class="font-weight-bold">SĐT: </span>
            <span>{{
              outerSerial.customer.phone | VMask("### ### ####")
            }}</span>
          </div>
        </v-card>
        <!-- End: Customer info -->
      </template>
      <!--      <span-->
      <!--        v-if="-->
      <!--          (serialInfo && serialInfo.serial_number) ||-->
      <!--            (outerSerial && outerSerial.customer_id)-->
      <!--        "-->
      <!--      ></span>-->
      <!--      <template v-else>-->
      <!--        <v-card class="grey lighten-4 px-5 py-4 mt-4" flat>-->
      <!--          <div class="text-center grey&#45;&#45;text text&#45;&#45;darken-2">-->
      <!--            Chưa có máy được chọn.-->
      <!--          </div>-->
      <!--        </v-card>-->
      <!--      </template>-->
      <!-- End: Serial info -->
    </div>

    <modal-add-outer-serial
      :guaranteeVoucherStatusRequest="guaranteeVoucherStatusRequest"
    />

    <modal-add-reason @addReason="handleAddReason" />
  </v-card>
</template>

<script>
import dayjs from "dayjs";
import ModalAddOuterSerial from "@/modules/Guarantee/pages/GuaranteeVouchers/Detail/components/modal/ModalAddOuterSerial";
import ModalAddReason from "@/core/components/modals/ModalGuaranteeRepair/ModalAddReason.vue";
import { RISK_STATUSES } from "@/modules/Guarantee/constants";

export default {
  props: {
    type: {
      type: String,
      require: true
    }
  },
  components: {
    ModalAddOuterSerial,
    ModalAddReason
  },
  data() {
    return {
      serialNumber: null,
      isShowButtonSaveServiceFee: false,
      isShowButtonSaveBranchID: false,
      key: 0,
      RISK_STATUSES,
      isUpdateDueDate: false
    };
  },
  computed: {
    roles() {
      return this.$store.getters["ROLE/rolesName"];
    },
    outerSerial() {
      return this.$store.getters["GUARANTEE_VOUCHER/outerSerial"];
    },
    branches() {
      return this.$store.getters["BRANCH/allBranches"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },
    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    },
    serialInfo() {
      return this.$store.getters["GUARANTEE_VOUCHER/serialInfo"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    suppliersRepairGuarantee() {
      return this.$store.getters["SUPPLIER/suppliers"];
    },
    textModule() {
      return this.type === "repair" ? "sửa chữa" : "bảo hành";
    },
    saleEmployees() {
      return this.employees.filter(i => i.staff_roles.includes("SALES"));
    },
    techEmployees() {
      return this.employees.filter(i => i.staff_roles.includes("ENGINEERING"));
    },

    dateDueDate: {
      get() {
        return this.guaranteeVoucher.due_date
          ? dayjs(this.guaranteeVoucher.due_date).format("YYYY-MM-DD")
          : null;
      },
      set(val) {
        if (val) {
          console.log("val", val);
          this.guaranteeVoucher.due_date = val;
          this.guaranteeVoucher.reason = "";
          this.key++;
        }
      }
    }
  },
  watch: {
    serialNumber(val) {
      if (!val || val === "") {
        this.guaranteeVoucher.serial_id = null;
        this.$store.dispatch("GUARANTEE_VOUCHER/setSerialInfo", null);
      }
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  created() {
    this.$store.dispatch("EMPLOYEE/getAllEmployees");
    if (this.branches && this.branches.length === 0) {
      this.$store.dispatch("BRANCH/getAllBranches");
    }
    if (this.serialInfo && this.serialInfo.id) {
      this.serialNumber = this.serialInfo.serial_number;
      this.guaranteeVoucher.serial_id = this.serialInfo.id;
    }
    this.$store.dispatch("CUSTOMER/getCustomers", {
      cur_page: 1,
      per_page: 100,
      search: null
    });
  },

  methods: {
    compareDateWithToday(d) {
      let converter = d;
      converter = converter.split("/");
      converter = `${converter[2]}-${converter[1]}-${converter[0]}`;

      const date = new Date(converter),
        today = new Date();

      date.setHours(0, 0, 0, 0);
      today.setHours(0, 0, 0, 0);

      return date >= today;
    },
    changeServiceFee(value) {
      this.guaranteeVoucher.service_fee = value;
      this.isShowButtonSaveServiceFee = true;
    },
    changeBranchID() {
      this.isShowButtonSaveBranchID = true;
    },
    getToday() {
      const date = new Date(),
        hour = date
          .getHours()
          .toString()
          .padStart(2, "0"),
        mins = date
          .getMinutes()
          .toString()
          .padStart(2, "0"),
        dd = date
          .getDate()
          .toString()
          .padStart(2, "0"),
        mm = (date.getMonth() + 1).toString().padStart(2, "0"),
        yyyy = date.getFullYear();

      return `${hour}:${mins} ${dd}/${mm}/${yyyy}`;
    },

    async getSerialInfoBySerialNumber() {
      if (!this.serialNumber || this.serialNumber === "") return;
      else {
        await this.$store.dispatch(
          "GUARANTEE_VOUCHER/getSerialInfoBySerialNumber",
          this.serialNumber
        );
        if (
          this.guaranteeVoucherStatusRequest &&
          this.guaranteeVoucherStatusRequest.value ===
            "error-getSerialInfoBySerialNumber"
        )
          this.$toast.show({
            name: "toast-action-alert",
            payload: {
              message:
                "Serial này còn hàng trong hệ thống, hoặc đang nằm trong phiếu bảo hành, sửa chữa khác."
            }
          });
        else if (
          this.guaranteeVoucherStatusRequest &&
          this.guaranteeVoucherStatusRequest.value ===
            "success-getSerialInfoBySerialNumber"
        ) {
          if (this.serialInfo.id)
            this.guaranteeVoucher.serial_id = this.serialInfo.id;
        }
      }
    },
    getNameRole(role) {
      return this.roles.filter(item => item.value === role)[0]["text"];
    },

    async openModalCustomer(customerId) {
      if (customerId) {
        await this.$store.dispatch("CUSTOMER/getCustomerById", customerId);
      } else {
        await this.$store.dispatch("CUSTOMER/resetCustomer");
      }

      this.$modal.show({ name: "modal-customer" });
    },

    onChangeBranch() {
      this.guaranteeVoucher.options.forEach(option => {
        option.serials = [];
        option.allSerials = [];
      });
    },

    uploadFile() {
      // do something export_id
    },
    showModalAddRoleServer() {
      this.$store.dispatch(
        "MODAL_SERIAL_PICKER/setBranchIDModelAddRoleServer",
        this.guaranteeVoucher && this.guaranteeVoucher.branch_id
          ? this.guaranteeVoucher.branch_id
          : null
      );
      this.$modal.show({
        name: "modal-add-role-server",
        payload: {
          model_id:
            this.guaranteeVoucher && this.guaranteeVoucher.id
              ? this.guaranteeVoucher.id
              : null,
          model_name: "App\\Models\\BHSC",
          dataNamespaced: "GUARANTEE_VOUCHER/getGuaranteeVoucherById",
          dataRequest: this.guaranteeVoucher.id
        }
      });
    },

    openPicker() {
      this.isUpdateDueDate = true;
    },

    closePicker() {
      this.isUpdateDueDate = false;
    },

    changeDueDate() {
      if (this.isUpdateDueDate) {
        this.$modal.show({
          name: "modal-add-reason"
        });
      }
    },

    handleAddReason(reason) {
      this.guaranteeVoucher.reason = reason;
      this.key++;
    }
  }
};
</script>

<style lang="scss" scoped>
.gap-1 {
  gap: 4px;
}
.gap-3 {
  gap: 12px;
}
.card-wrap {
  min-height: calc(100vh - 64px - 53px - 24px);
}
.text-subtitle {
  &::v-deep p {
    display: inherit;
    margin-bottom: 0;
  }
}
.save-service-fee {
  outline: none;
  background: transparent;
  padding: 0;
  box-shadow: none;
  margin-left: auto;
  color: #27bece;
  font-size: 12px;
}
.images {
  .image {
    padding-top: calc(100% / 3);
    width: calc(100% / 3 - 0.25rem);
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
</style>
