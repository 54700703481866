<template>
  <div class="text-body-1 px-5">
    <v-row class="my-0">
      <v-col class="scrollable col-general overflow-auto py-0" cols="4">
        <general :type="type"></general>
      </v-col>

      <v-col class="scrollable col-main py-0">
        <main-detail :type="type"></main-detail>
      </v-col>
    </v-row>

    <modal-guarantee-voucher-cancel></modal-guarantee-voucher-cancel>
  </div>
</template>

<script>
import General from "./components/General";
import MainDetail from "./components/MainDetail/index";
import ModalGuaranteeVoucherCancel from "./components/modal/ModalGuaranteeVoucherCancel";

export default {
  props: {
    type: {
      type: String,
      default: "guarantee"
    }
  },
  components: {
    General,
    MainDetail,
    ModalGuaranteeVoucherCancel
  },
  computed: {
    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    }
  },
  async created() {
    const route = this.$route;
    if (route.params.guaranteeId)
      await this.$store.dispatch("GUARANTEE_VOUCHER/resetGuaranteeVoucher");
    await this.$store.dispatch(
      "GUARANTEE_VOUCHER/getGuaranteeVoucherById",
      route.params.guaranteeId
    );

    // Getter guaranteeVoucher
    if (
      this.guaranteeVoucherStatusRequest.value ===
      "success-getGuaranteeVoucherById"
    ) {
      const guaranteeVoucher = await this.$store.getters[
        "GUARANTEE_VOUCHER/guaranteeVoucher"
      ];

      await this.$store.dispatch(
        "GUARANTEE_VOUCHER_COMMENT/getGuaranteeVoucherComments",
        route.params.guaranteeId
      );
      await this.$router
        .replace({
          query: {
            type: guaranteeVoucher.type === 1 ? "guarantee" : "repair"
          }
        })
        .catch(() => {});
    }
  },
  async beforeDestroy() {
    // Reset paymentList
    await this.$store.dispatch("SALE/setPaymentList", []);
  }
};
</script>

<style lang="scss" scoped>
.scrollable {
  max-height: calc(100vh - 64px - 53px - 24px);
  @media only screen and (max-width: 960px) {
    max-height: calc(100vh - 56px - 53px - 24px);
  }
}
.col-general {
  max-width: 320px;
}
.col-main {
  flex: 1;
}
</style>
