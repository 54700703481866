var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d_flex col-main flex-column"},[_c('div',{staticClass:"col-title rounded tp-border-thin pa-3",class:_vm.guaranteeVoucher.step === _vm.step ? 'green lighten-5' : null},[_c('span',{staticClass:"text-body-1 font-weight-medium col-step",class:_vm.guaranteeVoucher.step === _vm.step ? 'green lighten-3' : 'grey lighten-3'},[_vm._v(" "+_vm._s(_vm.step)+" ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.stepList[_vm.step - 1].name))])]),(_vm.guaranteeVoucher.id)?_c('div',{staticClass:"col-comments mt-2"},[(_vm.guaranteeVoucher.huy_status !== 1)?_c('div',{staticClass:"comment-form mt-2"},[_c('v-textarea',{staticClass:"text-body-1",attrs:{"append-icon":"mdi-send","prepend-inner-icon":"mdi-paperclip","auto-grow":"","background-color":"grey lighten-4","dense":"","flat":"","hide-details":"","placeholder":"Viết bình luận","rows":"1","solo":"","loading":_vm.loading,"disabled":_vm.guaranteeVoucher.step !== _vm.step},on:{"click:append":function($event){return _vm.createComment()},"click:prepend-inner":function($event){return _vm.$refs.imagesInput.click()}},model:{value:(_vm.comment.content),callback:function ($$v) {_vm.$set(_vm.comment, "content", $$v)},expression:"comment.content"}}),_c('input',{ref:"imagesInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*","multiple":""},on:{"change":_vm.handleFileUpload}})],1):_vm._e(),(_vm.guaranteeVoucherCommentsOfStep.length > 0)?_c('div',{staticClass:"col-comments--list"},_vm._l((_vm.guaranteeVoucherCommentsOfStep),function(comment){return _c('div',{key:comment.id,staticClass:"d-flex comment-box grey lighten-4 rounded pa-2 mt-2"},[_c('v-avatar',{staticClass:"comment-box--avatar mr-2",attrs:{"color":"deep-purple lighten-3","size":"26"}},[(
              comment.created_by_user.avatar &&
                comment.created_by_user.avatar !== ''
            )?_c('img',{attrs:{"alt":comment.created_by_user.name,"src":comment.created_by_user.avatar}}):_c('span',{staticClass:"white--text font-weight-black"},[_vm._v(_vm._s(comment.created_by_user.name[0]))])]),_c('div',{staticClass:"comment-box--main"},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(comment.created_by_user.name)+" ")]),(comment.files && comment.files.length)?_c('div',{staticClass:"d-flex flex-wrap gap-1 mt-1"},_vm._l((comment.files),function(file,index){return _c('img',{key:file + index,staticClass:"tp-border-thin grey lighten-3 flex-shrink-0 cursor-zoom-in",staticStyle:{"width":"48px","height":"48px"},attrs:{"src":file},on:{"click":function($event){return _vm.$emit('previewImage', file)}}})}),0):_vm._e(),_c('div',{staticClass:"comment-box--main__content"},[_vm._v(_vm._s(comment.content))]),_c('div',{staticClass:"grey--text text--darken-2 mt-1"},[_vm._v(" "+_vm._s(comment.created_at)+" ")])])],1)}),0):_vm._e()]):_vm._e(),(_vm.guaranteeVoucher.id && _vm.guaranteeVoucher.huy_status !== 1)?_c('div',{staticClass:"d-flex justify-space-between col-actions mt-4"},[(_vm.guaranteeVoucher.step === _vm.step)?[(
          (_vm.guaranteeVoucher.step !== 4 && _vm.guaranteeVoucher.step !== 1) ||
            (_vm.guaranteeVoucher.step === 4 &&
              _vm.isButton === 'repair' &&
              !_vm.guaranteeVoucher.order_id)
        )?_c('v-btn',{staticClass:"grey lighten-4 grey--text text--darken-2 rounded-lg",attrs:{"depressed":""},on:{"click":function($event){return _vm.updateGuaranteeVoucher(_vm.guaranteeVoucher.step - 1)}}},[_vm._v("Trở lại ")]):_vm._e(),(_vm.guaranteeVoucher.step < 4 && _vm.guaranteeVoucher.step !== 3)?_c('v-btn',{staticClass:"rounded-lg",attrs:{"disabled":_vm.guaranteeVoucherStatusRequest === 'loading-updateGuaranteeVoucher',"color":"primary","dark":"","depressed":""},on:{"click":function($event){return _vm.updateGuaranteeVoucher(_vm.guaranteeVoucher.step + 1)}}},[_vm._v(" Xong ")]):_vm._e(),(_vm.guaranteeVoucher.step === 3 && _vm.isButton === 'repair')?_c('v-btn',{staticClass:"rounded-lg",attrs:{"disabled":_vm.guaranteeVoucherStatusRequest === 'loading-updateGuaranteeVoucher',"color":"primary","dark":"","depressed":""},on:{"click":function($event){return _vm.confirmUpdateLastStep(4)}}},[_vm._v(" Xong ")]):_vm._e(),(_vm.guaranteeVoucher.step === 3 && _vm.isButton === 'guarantee')?_c('v-btn',{staticClass:"rounded-lg",attrs:{"disabled":_vm.guaranteeVoucherStatusRequest === 'loading-updateGuaranteeVoucher',"color":"primary","dark":"","depressed":""},on:{"click":function($event){return _vm.confirmUpdateLastStep(4)}}},[_vm._v(" Xong ")]):_vm._e(),(
          _vm.guaranteeVoucher &&
            !_vm.guaranteeVoucher.export_id &&
            _vm.guaranteeVoucher.step === 4 &&
            _vm.isButton === 'repair'
        )?_c('div',[_c('v-btn',{staticClass:"btn-bill",attrs:{"loading":_vm.orderStatusRequest.value === 'loading-exportRepairInvoice',"color":"primary","dark":""},on:{"click":_vm.handleXuatPhieu}},[_vm._v(" Xuất hoá đơn ")])],1):_vm._e()]:_vm._e()],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }