<template>
  <tp-modal name="modal-add-comment-have-files" width="450px" persistent>
    <v-card class="border-0">
      <div class="card px-5 py-3 align-center d-flex" height="56x">
        <div class="font-weight-bold">
          {{ comment.files ? comment.files.length : 0 }} tệp được chọn
        </div>
        <v-spacer></v-spacer>
      </div>
    </v-card>

    <div
      class="v-window-item tab-item grey lighten-3 pa-5 v-window-item--active"
    >
      <v-card class="pa-5">
        <div class="mb-4">
          <draggable
            v-model="comment.files"
            class="d-flex flex-wrap gap-3"
            draggable=".item"
            v-bind="dragOptions"
          >
            <div slot="header" class="f-flex flex-col">
              <input
                type="file"
                ref="imagesInput"
                accept="image/*"
                style="display: none"
                multiple
                @change="handleFileUpload"
              />

              <div
                class="gallery-add rounded"
                @click="$refs.imagesInput.click()"
              >
                <v-btn :loading="loading" icon>
                  <v-icon class="grey--text text--lighten-1" large>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </div>
            </div>

            <v-hover
              v-for="(item, index) in comment.files"
              :key="index"
              v-slot:default="{ hover }"
              class="item cursor-move"
            >
              <div class="gallery-item">
                <v-img
                  :aspect-ratio="1"
                  :src="item && item.slice(0, 5) !== 'blob:' ? item : undefined"
                  class="grey lighten-3 rounded tp-boder-thin"
                  height="100px"
                  width="100px"
                >
                  <v-fade-transition>
                    <v-row
                      v-if="hover"
                      align="center"
                      class="fill-height grey darken-4 ma-0"
                      justify="center"
                      style="opacity: 0.65"
                    >
                      <v-btn
                        color="white"
                        icon
                        @click="$emit('previewImage', item)"
                      >
                        <v-icon>mdi-eye-outline</v-icon>
                      </v-btn>

                      <v-btn color="white" icon @click="removeImage(item)">
                        <v-icon>mdi-delete-outline</v-icon>
                      </v-btn>
                    </v-row>
                  </v-fade-transition>
                  <template v-slot:placeholder>
                    <v-row
                      align="center"
                      class="fill-height ma-0"
                      justify="center"
                    >
                      <v-progress-circular
                        color="grey lighten-5"
                        indeterminate
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </v-hover>
          </draggable>
        </div>

        <div class="mt-3">
          <v-textarea
            v-model="comment.content"
            class="text-body-1"
            dense
            hide-details="auto"
            placeholder="Nội dung"
            outlined
            rows="2"
          ></v-textarea>
        </div>

        <div class="d-flex align-center justify-end gap-3">
          <v-btn
            class="grey lighten-4 grey--text text--darken-2 rounded-lg mt-5 px-7"
            depressed
            @click="$modal.hide({ name: 'modal-add-comment-have-files' })"
          >
            Hủy
          </v-btn>
          <v-btn
            class="rounded-lg mt-5 px-7"
            color="primary"
            depressed
            @click="handleCreateComment"
          >
            Lưu
          </v-btn>
        </div>
      </v-card>
    </div>
  </tp-modal>
</template>

<script>
import draggable from "vuedraggable";
import MediaService from "@/core/service/media.service";

export default {
  props: {
    comment: {
      type: Object
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      loading: false
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    }
  },

  methods: {
    removeImage(url) {
      this.$emit("updateImage", url);
      this.comment.files = this.comment.files.filter(image => image !== url);
    },
    async handleFileUpload() {
      const files = event.target.files;
      if (!files || files.length === 0) return;

      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("files[]", files[i]);
      }
      this.$refs.imagesInput.value = null;
      try {
        const response = await MediaService.uploadMultipleImages(formData);
        this.comment.files = [...this.comment.files, ...response.data];
      } catch (error) {
        console.log(error);
      }
    },

    async handleCreateComment() {
      await this.$store.dispatch(
        "GUARANTEE_VOUCHER_COMMENT/createGuaranteeVoucherComment",
        this.comment
      );
      this.$emit("resetComment");
      this.$modal.hide({ name: "modal-add-comment-have-files" });
    }
  }
};
</script>

<style lang="scss" scoped>
.border-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.v-card {
  box-shadow: none !important;
}
.cursor-move {
  cursor: move;
}
.ghost {
  opacity: 0.5;
  background: #ccc;
}
.gap-3 {
  gap: 12px;
}
.gallery {
  &-add {
    align-items: center;
    border: 2px dashed #e5e5e5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 100px !important;
    transition: all 0.4s ease;
    width: 100px !important;
    &:hover {
      background-color: #f9f9f9;
      border-color: #e0e0e0;
    }
  }
  &-item {
    display: flex;
    .v-image {
      border: 1px solid #e5e5e5;
    }
  }
}
</style>
