export const HEADERS_GUARANTEE_ACCESSORIES = [
  {
    text: "STT",
    align: "start",
    sortable: false,
    value: "id"
  },
  {
    text: "Tên sản phẩm",
    align: "start",
    sortable: false,
    value: "product_name"
  },
  {
    text: "Serials",
    align: "start",
    sortable: false,
    value: "serials"
  },
  {
    text: "Số lượng",
    align: "start",
    sortable: false,
    value: "quantity"
  },
  {
    text: "",
    align: "center",
    sortable: false,
    value: "action"
  }
];

export const HEADERS_GUARANTEE_ACCESSORIES_FULL = [
  {
    text: "STT",
    align: "start",
    sortable: false,
    value: "id"
  },
  {
    text: "Tên sản phẩm",
    align: "start",
    sortable: false,
    value: "product_name"
  },
  {
    text: "Serials",
    align: "start",
    sortable: false,
    value: "serials"
  },
  {
    text: "Số lượng",
    align: "start",
    sortable: false,
    value: "quantity"
  },
  {
    text: "Giá vốn TB",
    align: "start",
    sortable: false,
    value: "created_price"
  },
  {
    text: "Tổng giá trị",
    align: "start",
    sortable: false,
    value: "total"
  },
  {
    text: "",
    align: "center",
    sortable: false,
    value: "action"
  }
];

export const HEADERS_LISTING_GUARANTEE_VOUCHER = [
  {
    text: "Mã phiếu",
    align: "start",
    sortable: false,
    value: "code"
  },
  {
    text: "Sản phẩm",
    align: "start",
    sortable: false,
    value: "product_name"
  },
  {
    text: "Serial",
    align: "start",
    sortable: false,
    value: "serial_number"
  },
  {
    text: "Khách hàng",
    align: "start",
    sortable: false,
    value: "customer_name"
  },
  {
    text: "SĐT Khách hàng",
    align: "start",
    sortable: false,
    value: "customer_phone"
  },
  {
    text: "Tạo lúc",
    align: "start",
    sortable: true,
    value: "created_at"
  },
  {
    text: "Hạn xử lý",
    align: "start",
    sortable: true,
    value: "due_date"
  },
  {
    text: "Cập nhật lúc",
    align: "start",
    sortable: true,
    value: "updated_at"
  },
  {
    text: "Chi nhánh",
    align: "start",
    sortable: false,
    value: "branch_name"
  },
  {
    text: "Tạo bởi",
    align: "start",
    sortable: false,
    value: "created_by_user_name"
  },
  {
    text: "Phụ trách",
    align: "start",
    sortable: false,
    value: "assigned_to_user_name"
  },
  {
    text: "Mức độ rủi ro",
    align: "start",
    sortable: false,
    value: "risk"
  },
  {
    text: "Đối tác sửa chữa",
    align: "start",
    sortable: false,
    value: "service_supplier"
  },
  {
    text: "Tổng chi phí",
    align: "start",
    sortable: false,
    value: "total_fee"
  },
  {
    text: "Khách phải trả",
    align: "start",
    sortable: false,
    value: "customer_fee"
  },
  {
    text: "Khách đã trả",
    align: "start",
    sortable: false,
    value: "customer_value"
  },
  {
    text: "Khách nợ",
    align: "start",
    sortable: false,
    value: "customer_remains"
  },
  {
    text: "Trạng thái",
    align: "start",
    sortable: false,
    value: "step"
  },
  {
    text: "Đã hủy",
    align: "start",
    sortable: false,
    value: "huy_status"
  }
];

export const HEADERS_REPAIR_ACCESSORIES = [
  {
    text: "STT",
    align: "start",
    sortable: false,
    value: "id"
  },
  {
    text: "Tên sản phẩm",
    align: "start",
    sortable: false,
    value: "product_name"
  },
  {
    text: "Serials",
    align: "start",
    sortable: false,
    value: "serials"
  },
  {
    text: "Số lượng",
    align: "start",
    sortable: false,
    value: "quantity"
  },
  {
    text: "Đơn giá",
    align: "start",
    sortable: false,
    value: "unit_sold_price"
  },
  {
    text: "Chiết khấu",
    align: "start",
    sortable: false,
    value: "rebate_amount"
  },
  {
    text: "Giá cuốí",
    align: "start",
    sortable: false,
    value: "sold_price"
  },
  {
    text: "Thành tiền",
    align: "start",
    sortable: false,
    value: "total"
  },
  {
    text: "",
    align: "center",
    sortable: false,
    value: "action"
  }
];

export const CANCEL_STATUSES = {
  ALL: -1,
  NOT_CANCELED_YET: 0,
  CANCELLED: 1
};

export const GUARANTEE_STATUSES = [
  {
    label: "Đã tiếp nhận",
    value: 1
  },
  {
    label: "Đang xử lý",
    value: 2
  },
  {
    label: "Xử lý xong",
    value: 3
  },
  {
    label: "Đã trả khách",
    value: 4
  }
];

export const RISK_STATUSES = [
  {
    label: "Không",
    value: "NO_RISK",
    tooltip:
      "Không có rủi ro khách hàng không hài lòng. Khách không có tiền sử khiếu nại hoặc các vấn đề nổi cộm trước đó",
    color: "grey"
  },
  {
    label: "Rủi ro",
    value: "RISK",
    tooltip:
      "Có khả năng khách sẽ không hài lòng. Khách có thể đã bày tỏ lo lắng hoặc có yêu cầu đặc biệt trong quá trình bảo hành.",
    color: "orange"
  },
  {
    label: "Khẩn cấp",
    value: "EMERGENCY",
    tooltip:
      "Rủi ro cao về việc khách hàng không hài lòng hoặc có thể gây ảnh hưởng tiêu cực. Khách hàng đã từng có phản hồi tiêu cực hoặc vấn đề này dễ bị khách hàng công khai phản ánh.",
    color: "red"
  }
];
