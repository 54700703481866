<template>
  <tp-modal name="modal-confirm-give-customer" width="50%" max-width="768px">
    <v-card class="border-0">
      <div class="card px-5 py-3 align-center d-flex" height="56x">
        <div>
          <v-toolbar-title class="font-weight-bold">
            <span v-if="type === 'repair'"
              >Xác nhận trả sửa chữa cho khách</span
            >
            <span v-else>Xác nhận trả bảo hành cho khách</span>
          </v-toolbar-title>
          <!--          <p class="my-0">Thời gian trả: {{ guaranteeVoucher.created_at }}</p>-->
        </div>

        <v-spacer></v-spacer>

        <!--        <v-btn icon @click="closeModal()">-->
        <!--          <v-icon>mdi-close</v-icon>-->
        <!--        </v-btn>-->
      </div>
    </v-card>

    <!--        Start: actions-->
    <div
      class="v-window-item tab-item grey lighten-3 pa-5 v-window-item--active"
    >
      <v-card>
        <!--        Start: body -->
        <div class="card modal-body-scroll">
          <div class="tab-item lighten-3 pa-5">
            <div>
              <div class="font-weight-bold mb-2">Người trả máy:</div>
              <v-row class="d-flex align-center">
                <v-col cols="6" class="py-0">
                  <v-autocomplete
                    v-model="guaranteeVoucher.return_participant_id"
                    class="text-body-1 text-right"
                    hide-selected
                    clearable
                    dense
                    flat
                    hide-details
                    :items="employees"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ maxWidth: '332px' }"
                    no-data-text="Không có dữ liệu"
                    placeholder="Người trả, nhân viên trả"
                    outlined
                    single-line
                  />
                </v-col>
              </v-row>
            </div>
            <div class="mt-4">
              <div class="font-weight-bold mb-2">
                Khách hàng:
              </div>
              <v-row class="d-flex align-center mb-4">
                <v-col cols="6" class="py-0">
                  <v-autocomplete
                    v-if="
                      guaranteeVoucher.serial &&
                        guaranteeVoucher.serial.id &&
                        guaranteeVoucher.serial.bought_by
                    "
                    v-model="guaranteeVoucher.serial.bought_by.id"
                    class="text-body-1"
                    clearable
                    dense
                    flat
                    hide-details
                    :items="customers"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ maxWidth: '332px' }"
                    no-data-text="Không có dữ liệu"
                    no-filter
                    placeholder="Tìm khách hàng"
                    disabled
                    small-chips
                    solo
                    outlined
                  >
                  </v-autocomplete>
                  <v-autocomplete
                    v-if="
                      guaranteeVoucher.outer_serial &&
                        guaranteeVoucher.outer_serial.customer_id
                    "
                    v-model="guaranteeVoucher.outer_serial.customer_id"
                    class="text-body-1"
                    clearable
                    dense
                    flat
                    hide-details
                    :items="customers"
                    item-text="name"
                    item-value="id"
                    :menu-props="{ maxWidth: '332px' }"
                    no-data-text="Không có dữ liệu"
                    no-filter
                    placeholder="Tìm khách hàng"
                    small-chips
                    disabled
                    solo
                    outlined
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="6" class="py-0">
                  <div class="d-flex">
                    <v-checkbox
                      label="Người nhận thay"
                      class="mt-0 mb-0 pt-0"
                      hide-details
                      dense
                      v-model="guaranteeVoucher.alter_return"
                    ></v-checkbox>
                  </div>
                </v-col>
              </v-row>
              <v-textarea
                class="text-body-1"
                hide-details
                outlined
                placeholder="Ghi chú trả máy"
                rows="2"
                v-model="guaranteeVoucher.return_notes"
              ></v-textarea>
            </div>
            <div style="max-width: 60%">
              <div class="mt-4">
                <div class="d-flex flex-row align-center justify-space-between">
                  <div>Tải ảnh lên</div>
                  <v-btn
                    class="primary--text font-weight-bold text-none rounded px-0"
                    depressed
                    text
                    small
                    @click="$refs.file.click()"
                    style="min-width: auto;"
                  >
                    <v-icon class="mr-1" left>mdi-upload-outline</v-icon>
                    <!--                  Tải lên-->
                  </v-btn>
                  <input type="file" hidden ref="file" accept=".png, .jpg" />
                </div>
              </div>
              <v-divider class="mb-4 mt-0"></v-divider>
              <div class="d-flex align-center flex-wrap images">
                <!--                <div-->
                <!--                  v-for="item in 6"-->
                <!--                  :key="`image-${item}`"-->
                <!--                  :href="urlHotGirl"-->
                <!--                  class="image"-->
                <!--                  :style="`background-image: url('` + urlHotGirl + `')`"-->
                <!--                >-->
                <!--                  <v-btn-->
                <!--                    class="icon-remove-image"-->
                <!--                    x-small-->
                <!--                    icon-->
                <!--                    @click="closeModal()"-->
                <!--                  >-->
                <!--                    <v-icon>mdi-delete</v-icon>-->
                <!--                  </v-btn>-->
                <!--                </div>-->
              </div>
            </div>
            <v-alert class="yellow lighten-4 text-subtitle-1" dense>
              Tài liệu có chữ ký của khách theo quy định của công ty. Tải form
              trả máy sửa chữa, bảo hành tại
              <a
                href="https://d28jzcg6y4v9j1.cloudfront.net/archived/phieu_tiep_nhan_bhsc_24_10_2024.docx"
                >"Đây"</a
              >
            </v-alert>
          </div>
        </div>
      </v-card>
      <div class="d-flex align-center justify-center">
        <v-btn
          class="rounded-lg mt-5 px-7"
          color="primary"
          depressed
          :loading="isLoading"
          :disabled="!guaranteeVoucher.return_participant_id"
          @click="updateGuaranteeVoucher(4)"
        >
          Xác nhận
        </v-btn>
      </div>
    </div>
  </tp-modal>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "repair"
    }
  },
  data() {
    return {
      isLoading: false,
      customerSearchKey: null,
      urlHotGirl: "https://pbs.twimg.com/media/EfyoodPUYAMuAje.jpg"
    };
  },
  filters: {
    formatTime(d) {
      const dateTime = new Date(d),
        hour = String(dateTime.getHours()).padStart(2, "0"),
        min = String(dateTime.getMinutes()).padStart(2, "0"),
        date = String(dateTime.getDate()).padStart(2, "0"),
        month = String(dateTime.getMonth() + 1).padStart(2, "0"),
        year = dateTime.getFullYear();

      return `${hour}:${min} - ${date}/${month}/${year}`;
    }
  },
  watch: {
    customerSearchKey(val) {
      if (val && val.length > 0) {
        this.$store.dispatch("CUSTOMER/getCustomers", {
          cur_page: 1,
          per_page: 100,
          search: val
        });
      }
    }
  },
  computed: {
    customers() {
      return this.$store.getters["CUSTOMER/customers"];
    },
    user() {
      return this.$store.getters["AUTHENTICATION/user"];
    },
    employees() {
      return this.$store.getters["EMPLOYEE/allEmployees"];
    },
    guaranteeVoucher() {
      return this.$store.getters["GUARANTEE_VOUCHER/guaranteeVoucher"];
    },
    guaranteeVoucherStatusRequest() {
      return this.$store.getters["GUARANTEE_VOUCHER/statusRequest"];
    },
    saleReceipt() {
      return this.$store.getters["SALE_RECEIPT/saleReceipt"];
    },
    outerSerial() {
      return this.$store.getters["GUARANTEE_VOUCHER/outerSerial"];
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide({
        name: "modal-confirm-give-customer"
      });
    },

    async openModalCustomer(customerId) {
      if (customerId) {
        await this.$store.dispatch("CUSTOMER/getCustomerById", customerId);
      } else {
        await this.$store.dispatch("CUSTOMER/resetCustomer");
      }

      this.$modal.show({ name: "modal-customer" });
    },
    async updateGuaranteeVoucher(step) {
      this.isLoading = true;
      let role = "";
      // Set type
      if (this.$route.query.type === "guarantee") {
        this.guaranteeVoucher.type = 1;
        role = "GUARANTEE_RETURN";
      } else {
        this.guaranteeVoucher.type = 2;
        role = "REPAIR_RETURN";
      }

      const objectSender = JSON.parse(JSON.stringify(this.guaranteeVoucher));
      let serials = [];

      await objectSender.options.forEach(option => {
        const arr = option.serials.map(item => {
          return {
            id: item.id,
            sold_price:
              this.guaranteeVoucher.type === 1
                ? 0
                : option.unit_sold_price - Number(option.rebate_amount),
            unit_sold_price:
              this.guaranteeVoucher.type === 1 ? null : option.unit_sold_price
          };
        });

        serials = serials.concat(arr);
      });

      objectSender.xuat_serial_ids = serials;
      objectSender.outer_serial_id = this.outerSerial
        ? this.outerSerial.id
        : null;
      objectSender.step = step;

      // add role for user tra khach
      await this.$store.dispatch("ORDER/addInvoiceParticipant", {
        model_id: this.guaranteeVoucher.id,
        model_name: "App\\Models\\BHSC",
        user_id: this.guaranteeVoucher.return_participant_id,
        role: role,
        note: this.guaranteeVoucher.return_notes,
        docs: []
      });

      // Request create
      await this.$store.dispatch(
        "GUARANTEE_VOUCHER/updateGuaranteeVoucher",
        objectSender
      );
      if (
        this.guaranteeVoucherStatusRequest.value ===
        "success-updateGuaranteeVoucher"
      ) {
        if (objectSender.alter_return && objectSender.id) {
          // Create comment
          await this.$store.dispatch(
            "GUARANTEE_VOUCHER_COMMENT/createGuaranteeVoucherComment",
            {
              bh_sc_id: objectSender.id,
              step: 4,
              content: "Có người nhận thay"
            }
          );
        }
        this.closeModal();
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Đã lưu cập nhật"
          }
        });
      }
      if (
        this.guaranteeVoucherStatusRequest.value ===
        "error-updateGuaranteeVoucher"
      ) {
        this.$toast.show({
          name: "toast-action-alert",
          payload: {
            message: "Có lỗi xảy ra. Vui lòng kiểm tra lại sau!"
          }
        });
      }
      this.isLoading = false;
    }
  }
};
</script>

<style scoped lang="scss">
.border-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.v-card {
  box-shadow: none !important;
}
.images {
  .image {
    padding-top: 50px;
    width: 50px;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    object-fit: cover;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    &:hover .icon-remove-image {
      opacity: 1;
    }
    .icon-remove-image {
      opacity: 0;
      transition: all ease 0.2s;
      position: absolute;
      top: -5px;
      right: -5px;
      border-radius: 50%;
      cursor: pointer;
      color: #868686;
      background: #ffffff4a;
      border: 1px solid #868686;
      a {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}
</style>
